import { FC, useContext, useMemo } from 'react';

import { t } from 'i18next';

import { AuthContext } from 'context/AuthContext';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';

import TabItem from './components/TabItem';
import './index.scss';

interface Props {
  activeTabIndex: number;
  onClick: (index: number) => void;
}

const MenuTabs: FC<Props> = ({ activeTabIndex, onClick }) => {
  const { isUserFirstTime } = useContext(AuthContext);
  const { isLightingReady, isVisited: isVisitedLighting } = useContext(LightingContext);
  const { isDemographicsReady, isVisited: isVisitedDemographics } = useContext(DemographicsContext);
  const { isPhysicalDetailsReady, isVisited: isVisitedDetails } = useContext(PhysicalDetailsContext);
  const { iseRenderSettingsReady, isVisited: isVisitedRender } = useContext(RenderSettingsContext);

  const statuses = [false, isDemographicsReady, isLightingReady, isPhysicalDetailsReady, iseRenderSettingsReady];
  const visitedStatuses = [
    isVisitedDemographics || isVisitedLighting || isVisitedDetails || isVisitedRender,
    isVisitedDemographics,
    isVisitedLighting,
    isVisitedDetails,
    isVisitedRender,
  ];

  const tabsTitles = [
    t('firstTimeGeneration.generationSections.currentSettings'),
    t('firstTimeGeneration.generationSections.demographics'),
    t('firstTimeGeneration.generationSections.camera'),
    t('firstTimeGeneration.generationSections.physicalDetails'),
    t('firstTimeGeneration.generationSections.renderSettings'),
  ];

  const tabs = tabsTitles.map((title, index) => (
    <TabItem
      key={index}
      title={title}
      isActive={activeTabIndex === index}
      disabled={isUserFirstTime && activeTabIndex === 6}
      isCompleted={isUserFirstTime ? false : statuses[index]}
      isVisited={isUserFirstTime ? false : visitedStatuses[index]}
      onClick={() => {
        onClick(index);
      }}
      index={index}
    />
  ));

  return <div className="generation__section-tabs_container">{tabs}</div>;
};

export default MenuTabs;
