import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import api, { Queries } from 'api/axios';
import { mapPresetsDtoToPresets, Presets, PresetsDto } from 'api/models/presets';

const useGenerationPresets = () => {
  const token = Cookies.get('token');
  const { isLoading, data, error, refetch } = useQuery<Presets, AxiosError>({
    queryKey: [],
    queryFn: () => api.get<PresetsDto>(Queries.PRESETS).then(res => mapPresetsDtoToPresets(res.data)),
    enabled: !!token,
  });

  return { isLoading, error, data, refetch };
};

export default useGenerationPresets;
