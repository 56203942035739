import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import DashboardTabIcon from 'assets/svg/dashboard-tab-icon';
import GenerateTabIcon from 'assets/svg/generate-tab-icon';
import SecondaryButton from 'components/SecondaryButton';
import { RoutePath } from 'constants/routes';
import { AuthContext } from 'context/AuthContext';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';

import './index.scss';

import NavigationButton from '../NavigationButton';

const ButtonsBlock: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActiveTabIndex } = useContext(GenerationContext);
  const { resetGenerated, setUpscaled, setClothing, setActivity, setIsReseted } = useContext(GenerationContext);
  const { setIsSignedIn, setIsUserFirstTime, isUserFirstTime } = useContext(AuthContext);
  const { resetAllStates: resetRender } = useContext(RenderSettingsContext);
  const { resetAllStates: resetDemographics } = useContext(DemographicsContext);
  const { resetAllStates: resetLighting } = useContext(LightingContext);
  const { resetAllStates: resetDetails } = useContext(PhysicalDetailsContext);

  const onReset = () => {
    resetRender();
    resetDemographics();
    resetDetails();
    resetLighting();
    setUpscaled([]);
    setClothing('');
    setActivity('');
    resetGenerated();
  };
  const isGenerate = location.pathname === '/generate';
  const isDashboard = location.pathname === '/dashboard';

  const logout = () => {
    Cookies.remove('token');
    setIsSignedIn(false);
    navigate('../');
  };

  const onDashboardClick = () => {
    navigate(RoutePath.DASHBOARD);
  };

  const onGenerateClick = () => {
    navigate(RoutePath.GENERATE);
    isUserFirstTime && setIsUserFirstTime(false);
    onReset();
    setActiveTabIndex(5);
  };

  return (
    <div className="header_navigate-buttons-container">
      <NavigationButton
        active={isGenerate}
        icon={<GenerateTabIcon className="header__button_icon" color={isGenerate ? 'white' : ''} />}
        onClick={onGenerateClick}
        title={t('global.generate')}
      />
      <NavigationButton
        active={isDashboard}
        icon={<DashboardTabIcon className="header__button_icon" color={isDashboard ? 'white' : ''} />}
        onClick={onDashboardClick}
        title={t('header.dashboard')}
      />
      <SecondaryButton className="logout-button" onClick={logout} title={t('global.logout')} />
    </div>
  );
};

export default ButtonsBlock;
