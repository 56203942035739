import { NavigateFunction } from 'react-router-dom';

import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

import { AppPath } from '../constants/global';
import { AuthRoutePath, RoutePath } from '../constants/routes';

interface Props {
  user?: string;
  setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: NavigateFunction;
  newToken: string | null;
}

const useAuth = ({ user, newToken, setIsSignedIn, navigate }: Props) => {
  if (newToken) {
    Cookies.set('token', `${newToken}`);
  }

  const token = Cookies.get('token');
  if (!user) {
    if (token) {
      setIsSignedIn(true);
      location.pathname.includes(AppPath.LOGIN) && navigate(RoutePath.GENERATE);
      const decoded = jwtDecode(token);
      // @ts-ignore
      window._mfq.length === 0 && window._mfq.push(['setVariable', 'referrer', decoded.email]);
    } else if (!location.pathname.includes(AppPath.LOGIN)) {
      setIsSignedIn(false);
      navigate(AuthRoutePath.LOGIN);
    }
  }
};

export default useAuth;
