import { createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { RSelectOption } from 'components/Select';

type UpdatePreset = {
  updateId: (value: string) => void;
  id: string;
};

export type GeneratedData = {
  id: string;
  images: { id: string; url: string }[];
  status: string;
};

interface Context {
  eyesColor: RSelectOption | undefined;
  resetAllStates: () => void;
  makeup: RSelectOption | undefined;
  setMakeup: Dispatch<SetStateAction<RSelectOption | undefined>>;
  isPhysicalDetailsReady: boolean;
  hairColor: RSelectOption | undefined;
  setHairColor: Dispatch<SetStateAction<RSelectOption | undefined>>;
  setEyesColor: Dispatch<SetStateAction<RSelectOption | undefined>>;
  skinTone: RSelectOption | undefined;
  setSkinTone: Dispatch<SetStateAction<RSelectOption | undefined>>;
  appearance: RSelectOption | undefined;
  setAppearance: Dispatch<SetStateAction<RSelectOption | undefined>>;
  expression: RSelectOption | undefined;
  setExpression: Dispatch<SetStateAction<RSelectOption | undefined>>;
  facialHair: RSelectOption | undefined;
  setFacialHair: Dispatch<SetStateAction<RSelectOption | undefined>>;
  body: RSelectOption | undefined;
  setBody: Dispatch<SetStateAction<RSelectOption | undefined>>;
  isVisited: boolean;
  setIsVisited: Dispatch<SetStateAction<boolean>>;
  shavedHead: RSelectOption | undefined;
  setShavedHead: Dispatch<SetStateAction<RSelectOption | undefined>>;
}

export const PhysicalDetailsContext = createContext<Context>({} as Context);

interface Props {
  children: ReactNode;
}

export enum Presets {
  EYE = 'Eye color',
  HAIR = 'Hair color',
  SKIN_TONE = 'Skin tone',
}

export const PhysicalDetailsProvider: FC<Props> = ({ children }) => {
  const [eyesColor, setEyesColor] = useState<RSelectOption>();
  const [hairColor, setHairColor] = useState<RSelectOption>();
  const [skinTone, setSkinTone] = useState<RSelectOption>();
  const [appearance, setAppearance] = useState<RSelectOption>();
  const [body, setBody] = useState<RSelectOption>();
  const [makeup, setMakeup] = useState<RSelectOption>();
  const [expression, setExpression] = useState<RSelectOption>();
  const [facialHair, setFacialHair] = useState<RSelectOption>();
  const [shavedHead, setShavedHead] = useState<RSelectOption>();

  const [isVisited, setIsVisited] = useState(false);

  const isPhysicalDetailsReady = !!(
    eyesColor &&
    hairColor &&
    skinTone &&
    appearance &&
    body &&
    makeup &&
    expression &&
    facialHair
  );

  const resetAllStates = () => {
    setEyesColor(undefined);
    setHairColor(undefined);
    setMakeup(undefined);
    setBody(undefined);
    setAppearance(undefined);
    setExpression(undefined);
    setFacialHair(undefined);
    setSkinTone(undefined);
    setShavedHead(undefined);
    setIsVisited(false);
  };

  return (
    <PhysicalDetailsContext.Provider
      value={{
        body,
        isVisited,
        setIsVisited,
        setEyesColor,
        setHairColor,
        skinTone,
        setSkinTone,
        appearance,
        setAppearance,
        expression,
        setExpression,
        facialHair,
        setFacialHair,
        setBody,
        makeup,
        setMakeup,
        isPhysicalDetailsReady,
        resetAllStates,
        eyesColor,
        hairColor,
        shavedHead,
        setShavedHead,
      }}
    >
      {children}
    </PhysicalDetailsContext.Provider>
  );
};
