import { FC } from 'react';

import { t } from 'i18next';

import './index.scss';

const IntroBanner: FC = () => (
  <div className="banner-column">
    <div className="banner-row-end">
      <div className="banner-description">
        <p className="banner-text banner-finish">
          {t('firstTimeGeneration.introBanner.seeWhatBrookmannCanDo')} {t('firstTimeGeneration.introBanner.justClick')}
          <span className="banner-text banner-contrast" style={{ fontSize: 64 }}>
            {' '}
            {t('firstTimeGeneration.introBanner.inspireMe')}
          </span>{' '}
          {t('firstTimeGeneration.introBanner.toStart')}
        </p>
      </div>
    </div>
  </div>
);

export default IntroBanner;
