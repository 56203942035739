import { useContext } from 'react';

import { PresetOption, Presets, PresetTypes } from 'api/models/presets';
import { Draft } from 'api/queries/useDraft';
import useGenerationPresets from 'api/queries/useGenerationPresets';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';
import { getOptionsByType } from 'helpers/draft';
import { getImageAspectRatio } from 'pages/dashboard/components/CreationHistory/utils';
import { DemographicSelectorTitles } from 'pages/generation/components/Demographics/components/Selectors';

import usePrefillOption from './usePrefillOption';

interface Props {
  draft: Draft | undefined;
  generationPresets: Presets | undefined;
}

interface ILocalPreset {
  id: string;
  code: string;
  title: string;
  previewUrl: string;
}

const useSetDraftOptions = ({ draft, generationPresets }: Props) => {
  const { data: presets } = useGenerationPresets();

  const demographics = presets && presets[PresetTypes.DEMOGRAPHICS];
  const lighting = presets && presets[PresetTypes.LIGHTING];
  const details = presets && presets[PresetTypes.PHYSICAL_DETAILS];

  const { setActivity, setClothing } = useContext(GenerationContext);
  const { setNumberOfVariations, setRatios, setIsVisited: setIsVisitedRender } = useContext(RenderSettingsContext);
  const {
    setAge,
    setGender,
    setEthnicity,
    setAncestry,
    setIsVisited: setIsVisitedDemographics,
  } = useContext(DemographicsContext);

  const {
    setCameraType,
    setShot,
    setLighting: setLightingPreset,
    setLightingType,
    setIsVisited: setIsVisitedLighting,
  } = useContext(LightingContext);

  const {
    setAppearance,
    setBody,
    setExpression,
    setEyesColor,
    setFacialHair,
    setHairColor,
    setMakeup,
    setShavedHead,
    setSkinTone,
    setIsVisited: setIsVisitedDetails,
  } = useContext(PhysicalDetailsContext);

  const getLocalPreset = (preset: PresetTypes.DEMOGRAPHICS | PresetTypes.PHYSICAL_DETAILS | PresetTypes.LIGHTING) => {
    const { optionsByType } = getOptionsByType(generationPresets);
    return optionsByType[preset].filter((e: PresetOption) => draft?.presetOptionIds.includes(e.id));
  };

  const setDemographics = () => {
    const localPreset: ILocalPreset[] = getLocalPreset(PresetTypes.DEMOGRAPHICS);

    const ethnicityTitle = localPreset.find(item => item.code.includes('ETHNICITY'))?.title || '';
    const ancestryTitle =
      demographics!.slice(2).find(item => item.presetOptions.find(e => e.title === ethnicityTitle))?.title || '';
    const ethnicityOption = usePrefillOption(demographics, ancestryTitle, ethnicityTitle);
    const ancestryId = demographics?.find(el => el.title === ancestryTitle)?.id;
    const age = usePrefillOption(demographics, 'Age', localPreset.find(item => item.code.includes('AGE'))?.title ?? '');
    const gender = usePrefillOption(
      demographics,
      'Gender',
      localPreset.find(item => item.code.includes('GENDER'))?.title ?? '',
    );

    setAge(age);

    setGender(gender);
    if (ethnicityOption?.value) {
      setEthnicity({ ...ethnicityOption, parent: ancestryTitle });
    }
    ancestryId && setAncestry({ value: ancestryId, label: ancestryTitle });

    if (age || gender || ethnicityOption || ancestryId) {
      setIsVisitedDemographics(true);
    }
  };

  const setLighting = () => {
    const localPreset: ILocalPreset[] = getLocalPreset(PresetTypes.LIGHTING);
    const cameraTypes = ['CAMERA_TYPE_MIRRORLESS', 'CAMERA_TYPE_MEDIUM_FORMAT', 'CAMERA_TYPE_FILM_CAMERA_35MM'];

    const lightingTitle = localPreset.find(item => item.code.includes('LIGHTING'))?.title || '';
    const lightingTypeTitle =
      lighting!.slice(2).find(item => item.presetOptions.find(e => e.title === lightingTitle))?.title || '';
    const lightingOption = usePrefillOption(lighting, lightingTypeTitle, lightingTitle);
    const lightingTypeId = lighting?.find(el => el.title === lightingTypeTitle)?.id;
    const cameraType = usePrefillOption(
      lighting,
      'Camera type',
      localPreset.find(item => item.code.includes('CAMERA_TYPE') && cameraTypes.some(type => item.code.includes(type)))
        ?.title ?? '',
    );
    const shot = usePrefillOption(lighting, 'Shot', localPreset.find(item => item.code.includes('SHOT'))?.title ?? '');

    setCameraType(cameraType);
    setShot(shot);
    if (lightingOption?.value) {
      setLightingPreset({ ...lightingOption, parent: lightingTypeTitle });
    }
    lightingTypeId && setLightingType({ value: lightingTypeId, label: lightingTypeTitle });

    if (cameraType || shot || lightingOption || lightingTypeId) {
      setIsVisitedLighting(true);
    }
  };

  const setPhysicalDetails = () => {
    const localPreset: ILocalPreset[] = getLocalPreset(PresetTypes.PHYSICAL_DETAILS);
    const skinTone = usePrefillOption(
      details,
      'Skin tone',
      localPreset.find(item => item.code.includes('FLESH_TONE'))?.title ?? '',
    );
    const hairColor = usePrefillOption(
      details,
      'Hair color',
      localPreset.find(item => item.code.includes('HAIR_COLOR'))?.title ?? '',
    );
    const eyesColor = usePrefillOption(
      details,
      'Eye color',
      localPreset.find(item => item.code.includes('EYE_COLOR'))?.title ?? '',
    );
    const appearance = usePrefillOption(
      details,
      'Appearance',
      localPreset.find(item => item.code.includes('APPEARANCE_TYPE'))?.title ?? '',
    );
    const body = usePrefillOption(
      details,
      'Body type',
      localPreset.find(item => item.code.includes('BODY_TYP'))?.title ?? '',
    );
    const makeup = usePrefillOption(
      details,
      'Makeup',
      localPreset.find(item => item.code.includes('MAKEUP_TYPE'))?.title ?? '',
    );
    const expression = usePrefillOption(
      details,
      'Expression',
      localPreset.find(item => item.code.includes('EXPRESSION_TYPE'))?.title ?? '',
    );
    const facialHair = usePrefillOption(
      details,
      'Facial hair',
      localPreset.find(item => item.code.includes('FACIAL_HAIR_TYPE'))?.title ?? '',
    );
    const shavedHead = usePrefillOption(
      details,
      'Shaved head',
      localPreset.find(item => item.code.includes('SHAVED_HEAD_TYPE'))?.title ?? '',
    );

    setSkinTone(skinTone);
    setHairColor(hairColor);
    setEyesColor(eyesColor);
    setAppearance(appearance);
    setBody(body);
    setMakeup(makeup);
    setExpression(expression);
    setFacialHair(facialHair);
    setShavedHead(shavedHead);

    if (skinTone || hairColor || eyesColor || appearance || body || makeup || expression || facialHair || shavedHead) {
      setIsVisitedDetails(true);
    }
  };

  const setRender = () => {
    draft?.width && draft?.height && setRatios(getImageAspectRatio(draft.width, draft.height));
    draft?.numberOfImages && setNumberOfVariations(draft.numberOfImages);

    if ((draft?.width && draft?.height) || draft?.numberOfImages) {
      setIsVisitedRender(true);
    }
  };

  const setDescription = () => {
    draft?.activity && setActivity(draft.activity);
    draft?.clothing && setClothing(draft.clothing);
  };

  const setDraft = () => {
    setDemographics();
    setLighting();
    setPhysicalDetails();
    setRender();
    setDescription();
  };

  return { setDraft };
};

export default useSetDraftOptions;
