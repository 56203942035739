import { FC, useContext, useEffect, useState } from 'react';

import { t } from 'i18next';

import useUpdateDraft from 'api/queries/useUpdateDraft';
import CloseIcon from 'assets/svg/close-icon';
import RatioButton from 'components/RatioButton';
import VariationsButton from 'components/VariationsButton';
import { Ratios } from 'constants/images';
import { AuthContext } from 'context/AuthContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';

import './index.scss';

interface Props {
  onClose: () => void;
  isActive: boolean;
}

const RenderSettings: FC<Props> = ({ onClose, isActive }) => {
  const { numberOfVariations, setNumberOfVariations, ratios, setRatios, setIsVisited } =
    useContext(RenderSettingsContext);
  const { isUserFirstTime } = useContext(AuthContext);
  const { updateDraft } = useUpdateDraft();
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    if (isActive) {
      setIsVisited(true);
      if (numberOfVariations === undefined && ratios === undefined) {
        setRatios(Ratios.SQUARE);
        setNumberOfVariations(4);
        updateDraft({ numberOfVariations: 4, ratios: Ratios.SQUARE });
      }
    } else {
      setIsClose(false);
    }
  }, [isActive]);

  const handleClose = () => {
    onClose();
    setIsClose(true);
  };

  return (
    <div
      className={`first-time-generation__physical-details-container ${isActive && !isClose ? 'active' : isActive && isClose ? 'hide' : ''}`}
    >
      <div className="generate__render-setting-buttons_container">
        <p className="generate__render-setting-title">{t('generate.renders')}</p>
        <VariationsButton
          disabled={isUserFirstTime}
          onClick={() => {
            setNumberOfVariations(2);
            updateDraft({ numberOfVariations: 2 });
          }}
          title={'2'}
          active={numberOfVariations === 2}
        />
        <VariationsButton
          disabled={isUserFirstTime}
          onClick={() => {
            setNumberOfVariations(4);
            updateDraft({ numberOfVariations: 4 });
          }}
          title={'4'}
          active={numberOfVariations === 4}
        />
      </div>
      <div className="generate__render-setting-buttons_container">
        <p className="generate__render-setting-title">{t('generate.aspectRatio')}</p>
        <RatioButton
          disabled={ratios === Ratios.SQUARE || isUserFirstTime}
          active={ratios === Ratios.SQUARE}
          onClick={() => {
            setRatios(Ratios.SQUARE);
            updateDraft({ ratios: Ratios.SQUARE });
          }}
          title={Ratios.SQUARE}
          iconAspect={1}
        />
        <RatioButton
          disabled={ratios === Ratios.PORTRAIT || isUserFirstTime}
          active={ratios === Ratios.PORTRAIT}
          title={Ratios.PORTRAIT}
          onClick={() => {
            setRatios(Ratios.PORTRAIT);
            updateDraft({ ratios: Ratios.PORTRAIT });
          }}
          iconAspect={2 / 3}
        />
        <RatioButton
          disabled={ratios === Ratios.ALBUM || isUserFirstTime}
          active={ratios === Ratios.ALBUM}
          onClick={() => {
            setRatios(Ratios.ALBUM);
            updateDraft({ ratios: Ratios.ALBUM });
          }}
          title={Ratios.ALBUM}
          iconAspect={3 / 2}
        />
      </div>
      <button className="first-time-generation__physical-details-close-btn" onClick={handleClose}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default RenderSettings;
