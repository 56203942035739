import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { PresetTypes } from 'api/models/presets';
import useCharacters from 'api/queries/useCharacters';
import useGenerationPresets from 'api/queries/useGenerationPresets';
import useGetUserInfo from 'api/queries/useGetUserInfo';
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import { Ratios } from 'constants/images';
import { GenerationStatuses } from 'constants/statuses';
import { AuthContext } from 'context/AuthContext';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';
import usePrefillOption from 'hooks/usePrefillOption';
import usePresetOptions from 'hooks/usePresetOptions';
import GenerateBlock from 'pages/generation/components/GenerateBlock';
import PhysicalDetails from 'pages/generation/components/PhysicalDetails';

import CurrentSettingsModal from './components/CurrentSettingsModal';
import Demographics from './components/Demographics';
import { DemographicSelectorTitles } from './components/Demographics/components/Selectors';
import Lighting from './components/Lighting';
import { LightingSelectorTitles } from './components/Lighting/components/Selectors';
import MenuTabs from './components/MenuTabs';
import PageTitle from './components/PageTitle';
import RenderSettings from './components/RenderSettings';
import TopBlock from './components/TopBlock';
import WelcomeBack from './components/WelcomeBack';
import SelectModal from './components/WelcomeBack/components/SelectModal';
import './index.scss';

const Generation = () => {
  const { activeTabIndex, setActiveTabIndex, generationStatus, setIsStarted, inspireImage } =
    useContext(GenerationContext);
  const { isUserFirstTime, setIsUserFirstTime } = useContext(AuthContext);
  const {
    resetAllStates,
    setIsVisited: setIsVisitedRender,
    setRatios,
    setNumberOfVariations,
  } = useContext(RenderSettingsContext);
  const {
    setAge,
    setGender,
    setEthnicity,
    setAncestry,
    setIsVisited: setIsVisitedDemographics,
  } = useContext(DemographicsContext);
  const {
    setShot,
    setLighting,
    setLightingType,
    setCameraType,
    setIsVisited: setIsVisitedLighting,
  } = useContext(LightingContext);

  const { data: presets } = useGenerationPresets();
  const { data, isLoading } = useGetUserInfo();
  const [currentSettingsModalVisible, setCurrentSettingsModalVisible] = useState<boolean>(false);
  const { data: generationPresets } = useGenerationPresets();
  const demographics = presets && presets[PresetTypes.DEMOGRAPHICS];
  const lighting = presets && presets[PresetTypes.LIGHTING];
  const [showInspireMeModal, setShowInspireMeModal] = useState(false);
  const { data: characters } = useCharacters();
  const { t } = useTranslation();
  const agePrefillOption = usePrefillOption(demographics, 'Age', 'Mid to late 30s');
  const genderPrefillOption = usePrefillOption(demographics, 'Gender', 'Female');
  const shotPrefillOption = usePrefillOption(lighting, 'Shot', 'Medium shot');

  const cameraPrefillOption = usePrefillOption(lighting, 'Camera type', 'Mirrorless');
  const africanDiasporaPrefillOption = usePrefillOption(demographics, 'African diaspora', 'African American');
  const africanDiasporaId = demographics?.find(el => el.title === 'African diaspora')?.id;
  const lightingPrefillOption = usePrefillOption(lighting, 'Outdoor lighting', 'Patchy sun');
  const outdoorLightingId = lighting?.find(el => el.title === 'Outdoor lighting')?.id;
  const handleClose = () => {
    setTimeout(() => setActiveTabIndex(5), 400);
  };

  usePresetOptions();
  useEffect(() => {
    if (data) {
      (data.numberOfGenerations === 0) !== isUserFirstTime && setIsUserFirstTime(data.numberOfGenerations === 0);
    }
  }, [data]);

  useEffect(() => {
    if (
      agePrefillOption &&
      genderPrefillOption &&
      africanDiasporaPrefillOption &&
      shotPrefillOption &&
      lightingPrefillOption &&
      isUserFirstTime &&
      outdoorLightingId &&
      cameraPrefillOption &&
      africanDiasporaId
    ) {
      resetAllStates();
      setAge(agePrefillOption);
      setGender(genderPrefillOption);
      setAncestry({ value: africanDiasporaId, label: DemographicSelectorTitles.AFRICAN_DIASPORA });
      setEthnicity({ ...africanDiasporaPrefillOption, parent: DemographicSelectorTitles.AFRICAN_DIASPORA });
      setCameraType(cameraPrefillOption);
      setShot(shotPrefillOption);
      setLightingType({ value: outdoorLightingId, label: LightingSelectorTitles.OUTDOOR_LIGHTING });
      setLighting({ ...lightingPrefillOption, parent: LightingSelectorTitles.OUTDOOR_LIGHTING });
      setRatios(Ratios.SQUARE);
      setNumberOfVariations(4);

      setIsVisitedRender(true);
      setIsVisitedDemographics(true);
      setIsVisitedLighting(true);
    }
  }, [demographics, isUserFirstTime, lighting]);

  return (
    <div
      style={
        isUserFirstTime || activeTabIndex === 6
          ? {
              background: `url(https://api.ai-image-editor.dev2.scrij.com/background-images/random)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          : {
              backgroundColor: '#232320',
            }
      }
      className="generate__generation_container"
    >
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ display: 'flex', width: 1240, flexDirection: 'column' }}>
          <PageTitle isLoading={isLoading} />
          <TopBlock isLoading={isLoading} />
        </div>
        <div className="generate__generation-section_container">
          <MenuTabs
            onClick={(index: number) => {
              if (index === 0) {
                setCurrentSettingsModalVisible(true);
              } else {
                setActiveTabIndex(index);
                setIsStarted(true);
              }
            }}
            activeTabIndex={activeTabIndex}
          />
          {(activeTabIndex === 6 || activeTabIndex === 5) &&
            ((generationStatus === GenerationStatuses.FETCHING && inspireImage) ||
              generationStatus !== GenerationStatuses.FETCHING) && (
              <div className="inspire-container">
                {generationStatus === GenerationStatuses.FETCHING && inspireImage && (
                  <img style={{ width: 170, height: 170, borderRadius: 8 }} src={inspireImage} />
                )}
                {isUserFirstTime ? (
                  <PrimaryButton
                    className="inspire-primary"
                    onClick={() => {
                      setShowInspireMeModal(true);
                    }}
                    disabled={generationStatus === GenerationStatuses.FETCHING}
                    title={t('generate.inspireMe')}
                  />
                ) : (
                  <SecondaryButton
                    className="inspire-secondary"
                    onClick={() => {
                      setShowInspireMeModal(true);
                    }}
                    disabled={generationStatus === GenerationStatuses.FETCHING}
                    title={t('generate.inspireMe')}
                  />
                )}
              </div>
            )}
          <div className={`tabs-container ${activeTabIndex === 5 ? 'hide' : ''}`}>
            <Demographics isActive={activeTabIndex === 1} onClose={handleClose} />
            <Lighting isActive={activeTabIndex === 2} onClose={handleClose} />
            <PhysicalDetails isActive={activeTabIndex === 3} onClose={handleClose} />
            <RenderSettings isActive={activeTabIndex === 4} onClose={handleClose} />
            <WelcomeBack isActive={activeTabIndex === 6} />
          </div>
          {isUserFirstTime &&
            generationStatus !== GenerationStatuses.FETCHING &&
            characters &&
            activeTabIndex === 6 && (
              <div className="ftu-images-container">
                {characters.map((character, index) => {
                  return index < 4 ? <img key={index} className="image" src={character.previewUrl} /> : null;
                })}
              </div>
            )}
          {activeTabIndex === 5 && <GenerateBlock />}
        </div>
      </div>
      {characters && (
        <SelectModal
          title="Choose one"
          subtitle="as a starting point for your character. You can change any settings later."
          generationPresets={generationPresets}
          options={characters}
          show={showInspireMeModal}
          columns={3}
          onClose={() => setShowInspireMeModal(false)}
        />
      )}
      {currentSettingsModalVisible && <CurrentSettingsModal setVisible={setCurrentSettingsModalVisible} />}
      <ToastContainer
        transition={Slide}
        stacked={true}
        position={'bottom-right'}
        theme={'dark'}
        limit={5}
        autoClose={2000}
      />
    </div>
  );
};

export default Generation;
