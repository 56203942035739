import { FC, useContext, useEffect, useMemo } from 'react';

import { t } from 'i18next';

import useDeleteDraft from 'api/queries/useDeleteDraft';
import useDraft from 'api/queries/useDraft';
import useGenerate from 'api/queries/useGenerate';
import { useGenerationHistory } from 'api/queries/useGenerationHistory';
import useGenerationPresets from 'api/queries/useGenerationPresets';
import PrimaryButton from 'components/PrimaryButton';
import { GenerationStatuses } from 'constants/statuses';
import { AuthContext } from 'context/AuthContext';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';
import useSetDraftOptions from 'hooks/useSetDraftOptions';

import './index.scss';

interface Props {
  isActive: boolean;
}

const WelcomeBack: FC<Props> = ({ isActive }) => {
  const { data: draft } = useDraft();
  const { data: generationPresets } = useGenerationPresets();
  const { data } = useGenerationHistory();
  const { setDraft } = useSetDraftOptions({ draft, generationPresets });
  const { isVisited: isVisitedLighting } = useContext(LightingContext);
  const { isVisited: isVisitedDemographics } = useContext(DemographicsContext);
  const { isVisited: isVisitedDetails } = useContext(PhysicalDetailsContext);
  const { isVisited: isVisitedRender } = useContext(RenderSettingsContext);
  const { data: newGenerate, generate, isPending } = useGenerate();
  const oneTabVisited = isVisitedDemographics || isVisitedLighting || isVisitedDetails || isVisitedRender;
  const { deleteDraft } = useDeleteDraft();
  const { isUserFirstTime } = useContext(AuthContext);
  const { resetGenerated, waitGeneratedImages, setActiveTabIndex, inspireImage, generationStatus } =
    useContext(GenerationContext);

  const lastGeneratedToDraft = useMemo(() => {
    if (!data?.pages[0].result[0]) {
      return undefined;
    }
    return {
      activity: data?.pages[0].result[0].meta.prompts[1].Activity || '',
      clothing: data?.pages[0].result[0].meta.prompts[0].Clothing || '',
      width: data?.pages[0].result[0].options.imageWidth,
      height: data?.pages[0].result[0].options.imageHeight,
      numberOfImages: data?.pages[0].result[0].options.numberOfImages,
      presetOptionIds: data?.pages[0].result[0].presetOptionIds || [],
    };
  }, [data]);

  const { setDraft: setPreset } = useSetDraftOptions({ draft: lastGeneratedToDraft, generationPresets });

  useEffect(() => {
    if (newGenerate?.status === 'PENDING') {
      setPreset();
      waitGeneratedImages();
    }
  }, [newGenerate?.status]);

  useEffect(() => {
    if (generationStatus === GenerationStatuses.FETCHED) {
      deleteDraft();
      setActiveTabIndex(5);
    }
  }, [generationStatus]);

  const onGenerate = () => {
    if (lastGeneratedToDraft && lastGeneratedToDraft.presetOptionIds.length >= 14) {
      resetGenerated();
      generate(lastGeneratedToDraft);
    }
  };

  return (
    <div className={`first-time-generation__more-container ${isActive ? 'active' : 'hide'}`}>
      <div className="description-container">
        {generationStatus !== GenerationStatuses.FETCHING && (
          <>
            {draft !== null && !oneTabVisited && (
              <p className="inspire-title">
                <span className="red">{t('generate.click')}</span>{' '}
                <span className="white">{t('generate.finishSetup')}</span>{' '}
                <span className="red">{t('generate.toFinishSetting')}</span>
              </p>
            )}
            {draft === null && !oneTabVisited && (
              <p className="inspire-title">
                <span className="dark">{t('generate.click')}</span>{' '}
                <span className="white">{t('generate.moreLikeThis')}</span>{' '}
                <span className="dark">{t('generate.forNewVariationsOfYourLast')}</span>
              </p>
            )}
            {!isUserFirstTime && (
              <p className="inspire-title">
                <span className="dark">{t('generate.clickA')}</span>{' '}
                <span className="white">{t('generate.tabOnTheLeft')}</span>{' '}
                <span className="dark">{t('generate.toStartFromScratch')}</span>
              </p>
            )}
            {!isUserFirstTime && (
              <p className="inspire-title">
                <span className="dark">{t('generate.orClick')}</span>{' '}
                <span className="white">{t('generate.inspireMe')}</span>
                {'.'}
              </p>
            )}
          </>
        )}
      </div>
      <div className="buttons">
        {draft !== null && !oneTabVisited && (
          <PrimaryButton
            className="finish"
            onClick={() => {
              setDraft();
            }}
            title={t('generate.finishSetup')}
          />
        )}
        {draft === null && !inspireImage && (
          <div style={{ gap: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img
              style={{ width: 170, height: 170, borderRadius: 8 }}
              src={data?.pages[0]?.result[0]?.images[0]?.url || ''}
            />
            <PrimaryButton
              className="finish"
              disabled={isPending || generationStatus !== GenerationStatuses.NONE}
              onClick={() => {
                data?.pages[0].result[0] && onGenerate();
              }}
              title={t('generate.moreLikeThis')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomeBack;
