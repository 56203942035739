import { createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { RSelectOption } from 'components/Select';

interface Context {
  age: RSelectOption | undefined;
  setAge: Dispatch<SetStateAction<RSelectOption | undefined>>;
  gender: RSelectOption | undefined;
  setGender: Dispatch<SetStateAction<RSelectOption | undefined>>;
  isDemographicsReady: boolean;
  isVisited: boolean;
  setIsVisited: Dispatch<SetStateAction<boolean>>;
  resetAllStates: () => void;
  ethnicity: RSelectOption | undefined;
  setEthnicity: Dispatch<SetStateAction<RSelectOption | undefined>>;
  ancestry: RSelectOption | undefined;
  setAncestry: Dispatch<SetStateAction<RSelectOption | undefined>>;
}

export const DemographicsContext = createContext<Context>({} as Context);

interface Props {
  children: ReactNode;
}

export const DemographicsProvider: FC<Props> = ({ children }) => {
  const [age, setAge] = useState<RSelectOption>();
  const [gender, setGender] = useState<RSelectOption>();
  const [ethnicity, setEthnicity] = useState<RSelectOption>();
  const [ancestry, setAncestry] = useState<RSelectOption>();

  const [isVisited, setIsVisited] = useState(false);

  const isDemographicsReady = !!(age && gender && ethnicity);

  const resetAllStates = () => {
    setAge(undefined);
    setGender(undefined);
    setEthnicity(undefined);
    setAncestry(undefined);
    setIsVisited(false);
  };

  return (
    <DemographicsContext.Provider
      value={{
        isVisited,
        setIsVisited,
        age,
        ethnicity,
        setEthnicity,
        ancestry,
        setAncestry,
        setAge,
        gender,
        setGender,
        isDemographicsReady,
        resetAllStates,
      }}
    >
      {children}
    </DemographicsContext.Provider>
  );
};
